import { useState } from 'react'
import { debounce } from '@shopper/ui/src'

export function useTemporaryState(initialValue: boolean, delay: number): [boolean, () => void] {
  const [state, setState] = useState(initialValue)

  const deactivate = debounce(() => {
    setState(false)
  }, delay)

  const activate = () => {
    setState(true)
    deactivate()
  }

  return [state, activate]
}
