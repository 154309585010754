import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { ProductVariantDataSize } from '@centrito/api/nest/platform/database/domain'
import { productPresentationInitialState } from './initialState'
import { type ProductPresentationStore } from './types'

// Crear el store de Zustand
export const useProductPresentationStore = create<ProductPresentationStore>()(
  devtools(
    immer((set, get) => ({
      ...productPresentationInitialState,

      setPresentationsFromVariant: (variant): void => {
        const { presentationIndex, selectedPresentation, userSelectColor } = get()

        const presentationsTemp = variant.variant.data.sizes.filter(
          (presentation: ProductVariantDataSize) => presentation.available,
        )
        const validPresentationIndex =
          presentationIndex < presentationsTemp.length ? presentationIndex : 0

        const newSelectedPresentation =
          selectedPresentation && userSelectColor
            ? presentationsTemp.find(
                (p: ProductVariantDataSize) => p.name === selectedPresentation.name,
              )
            : undefined

        set(() => ({
          selectedPresentation: newSelectedPresentation,
          presentations: presentationsTemp,
          presentationIndex: validPresentationIndex,
          userSelectColor: false,
        }))
      },
      setShowValidation: (show: boolean): void => {
        set((state) => {
          state.showValidation = show
        })
      },
      setPresentationsWithQuantity: (presentationsWithQuantity): void =>
        set(() => ({ presentationsWithQuantity }), undefined, {
          type: 'setPresentationsWithQuantity',
        }),
      setSelectedPresentation: (presentation): void => {
        if (presentation?.name === get().selectedPresentation?.name) return

        set(() => ({ selectedPresentation: presentation }), undefined, {
          type: 'setSelectedPresentation',
        })
      },
      setUserSelectColor: (userSelectColor: boolean): void => {
        set(() => ({ userSelectColor }))
      },
      setIsScrollToValidation: (isScrollToValidation: boolean): void => {
        set(() => ({ isScrollToValidation }))
      },
      setProductImageHeight: (productImageHeight: number): void => {
        set(() => ({ productImageHeight }))
      },
      setHasColors: (hasColors: boolean): void => {
        set(() => ({ hasColors }))
      },
      changePresentationQuantity: (presentationName, change): void =>
        set(
          (state) => {
            state.presentationsWithQuantity = state.presentationsWithQuantity.map(
              (presentationWithQuantity) => {
                if (presentationWithQuantity.name === presentationName) {
                  return {
                    ...presentationWithQuantity,
                    quantity: presentationWithQuantity.quantity + change,
                  }
                }

                return presentationWithQuantity
              },
            )
          },
          undefined,
          { type: 'presentation' },
        ),
    })),
    {
      name: 'productPresentationStore',
    },
  ),
)
